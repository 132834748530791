$primary01: #3f3f3f;
$primary02: #3942B0;
$primary05: #00263e;
$secondary01: #292f7c;
$secondary02: #00a9e0;
$secondary03: #bfbfbf;
$pop03: #f2a900;

@mixin setLinkSelectorStyle {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

#okta-sign-in {
  overflow: hidden;
  border-radius: 8px;
  font-family: "Work Sans", Arial, Helvetica, sans-serif !important;
  
  a {
    text-decoration: none;
  }

  .infobox-error::before {
    background-color: #e34843;
    border-radius: 8px 0 0 8px;
  }

  .o-form-has-errors {
    .infobox-error {
      display: block !important;
      margin-bottom: 15px;
      border-radius: 8px;
    }
    .hidden-error {
      display: none !important;
    }
  }

  .hidden-error {
    display: none !important;
  }

  .o-form-head {
    &.okta-form-title {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 15px;
      text-align: center;
      text-transform: none;
      color: $secondary01;
      font-weight: 600;
    }
  }

  .o-form-explain {
    &.okta-form-subtitle {
      font-size: 15px;
      color: $primary01;
    }
  }

  .o-form {
    
    .o-form-input {
      .o-form-control {
        position: relative;
        border-radius: 8px;
      }
    }
  }

  .o-form-content {
    padding-bottom: 0px;
    .foundUser {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      word-break: break-all;

      .bold {
        font-weight: bold;
      }
    }
  }

  .auth-content {
    // for reset password redirect link
    padding: 0px;
    .link {
      color: $primary02 !important; //change this...
      @include setLinkSelectorStyle {
        color: $primary05;
        text-decoration: underline;
      }
    }
    .auth-content-inner {
      .idp-discovery, .primary-auth, .password-reset-email-sent, .recovery-loading {
        padding: 0px 15px 15px 15px;
      }
      .forgot-password {
        padding: 0px 15px 20px;
      }
      .createAccount {
        font-size: 16px;
        text-align: center;
        margin: 20px 0;
        .link {
          color: $primary02;
          @include setLinkSelectorStyle {
            color: $primary05;
            text-decoration: underline;
          }
          &:focus {
            outline: 2px solid $pop03;
          }
        }
      }
    }
  }

  .auth-footer {
    margin-top: 20px;
    font-size: 15px;
    .link {
      color: $primary02 !important;
      @include setLinkSelectorStyle {
        color: $primary05 !important;
        text-decoration: underline !important;
      }
      &:focus {
        outline: 2px solid $pop03 !important;
      }
      &.goto {
        float: left;
      }
    }
  }

  .o-form label {
    font-size: 14px;
  }

  &.auth-container {
    .main-container {
      color: #3f3f3f;
    }

    .okta-form-subtitle {
      font-size: 15px;
      color: #3f3f3f;
    }

    .button-primary {
      background: $primary02;
      background-color: $primary02;
      font-size: 16px;
      font-family: "Work Sans",Helvetica,sans-serif !important;
      border-radius: 8px;
      height: 40px;
      line-height: 24px;
      display: -webkit-box;
      box-shadow: none;
      transition: background 0.35s,border-color 0.35s,box-shadow 0.35s,color 0.35s;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      @include setLinkSelectorStyle {
        background: $secondary01;
        background-color: $secondary01;
      }
    }

    &.main-container {
      color: $primary01;
      box-shadow: none;
      border-color: #ddd;
      width: 600px;
    }

    // for the actual links
    .link {
      color: $primary02;
      @include setLinkSelectorStyle {
        color: $primary05;
        text-decoration: underline;
      }
    }
  }

  .auth-org-logo {
    display: block;
    margin: 0 auto;
    max-width: 400px;
    max-height: 80px;
  }

  .auth-header {
    padding: 30px 0px 75px;
  }
  
  .error-16-small:before {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' class='icon' xmlns='http://www.w3.org/2000/svg' fill='%23D32821' height='20' width='20'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
  }
  
  .icon.icon-16.error-16-small {
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    padding-top: 4px;
    display: inline-block;
  }

  .okta-form-input-error.o-form-input-error.o-form-explain {
    padding-top: 4px;
    padding-left: 24px;
    letter-spacing: 0.16px;
  }

  div:has(> #recoveryToken) {
    border: 1px solid #707070;
  }
  
  #hacky-inserter .o-form-explain {
    margin-bottom: 20px;
  }
  
  #hacky-inserter label[for="recoveryToken"] {
    padding: 7px 10px 7px 0;
    font-weight: 600;
    height: auto;
    line-height: 16px;
  }
  
  #hacky-inserter #recoveryToken__desc {
    margin-top: 4px;
  }

  #hacky-inserter #recoveryToken__desc span {
    padding-right: 4px;
  }

  .o-form-head+.o-form-explain {
    text-align: left;
  }
  
  #hacky-inserter-institution-picker {
    margin: -25px 0 30px;
    #institution-picker-toggle-button {
      padding: 0 8px 0 4px;
      border: 1px solid #8c8c96;
    }
    #institution-picker-toggle-button:focus-within {
      box-shadow: 0 0 8px #51cbee;
      border-radius: 8px;
    }
    div:has(> #institution-picker-menu) {
      background: #FFFFFF;
      border: 1px solid #D4D4D4;
      color: #454545;
      background: #FFFFFF;
      border: 1px solid;
      border-color: #D4D4D4;
      margin-top: 4px;
      padding: 4px 0 0;
    }
    #institution-picker-menu {
      margin: 0 0 4px;
      li {
        -webkit-align-self: center;
        background: transparent;
        border: 2px solid;
        border-color: transparent;
        line-height: 24px;
        margin: 0;
        padding: 8px 16px;
        font-size: 16px;
        :only-child {
          padding-top: 8px;
        }
      }
      li[aria-selected="true"] {
        background: rgb(245, 245, 245);
        border: 2px solid rgb(0, 116, 183);
      }
    }
    #institution-picker-input {
      font-size: 16px;
      box-shadow: none;
    }
    #institution-picker-input:focus {
      outline: none;
    }
    #institution-picker-label {
      padding: 7px 10px 7px 0;
      font-weight: 600;
      height: auto;
      line-height: 16px;
    }
    span:has(> #institution-picker-input) {
      box-shadow: none;
    }
    #institution-picker__desc {
      margin-top: 4px;
      width: 100%;
    }
    #institution-picker__desc span {
      padding-right: 4px;
    }
  }
  
  a.cengage-support-link {
    color: #3942B0;
  }
  
  .alfred-password-set-error > div {
    background: #FDEFEE;
    border: 1px solid #D32821;
  }

  .alfred-password-set-error > div > span {
    margin-right: 1px;
    padding: 0 8px 0 12px;
  }

  .alfred-password-set-error > div > div {
    padding: 12px 4px 12px 0;
    font-family: "Work Sans", Helvetica, sans-serif;
    font-size: 16px;
  }
}

hr {
  border: none;
  height: 1px;
  margin: 20px 0px;
  color: #ddd; /* old IE */
  background-color: #ddd; /* Modern Browsers */
}

input {
  &:focus {
    border-color: 2px solid $pop03 !important;
    outline: 0 !important;
  }
}

#okta-sign-in.auth-container .okta-form-input-field input {
  font-size: 16px;
  font-family: "Work Sans",Helvetica,sans-serif;
}